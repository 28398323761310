<template>
  <div class="container">
    <div class="head">
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">总采购次数</div>
        <div class="dfcs">
          <span>{{ purchaseStatistics.sum_purchase_count }}</span>
          <img v-if="purchaseStatistics.day_yoy_sum_purchase_count_percentage >= 0" src="https://source.aidogcatpet.com/forit/up.png" alt="">
          <img v-else src="https://source.aidogcatpet.com/forit/down.png" alt="">
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">总采购库存数</div>
        <div class="dfcs">
          <span>{{ purchaseStatistics.sum_purchase_sum_stock }}</span>
          <img v-if="purchaseStatistics.day_yoy_sum_purchase_sum_stock_percentage >= 0" src="https://source.aidogcatpet.com/forit/up.png" alt="">
          <img v-else src="https://source.aidogcatpet.com/forit/down.png" alt="">
        </div>
      </div>
     
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">总采购金额</div>
        <div class="dfcs">
          <span>{{ purchaseStatistics.sum_purchase_price }}</span>
          <img v-if="purchaseStatistics.day_yoy_sum_purchase_price_percentage >= 0" src="https://source.aidogcatpet.com/forit/up.png" alt="">
          <img v-else src="https://source.aidogcatpet.com/forit/down.png" alt="">
        </div>
      </div>
    </div>
    <div class="screen">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="input" placeholder="采购单号"></el-input>
      </div>
      <div>
        状态：
        <el-select v-model="statusValue" clearable placeholder="请选择">
          <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div>
        日期：
        <el-date-picker v-model="value1" type="datetimerange" range-separator="至" :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <el-button type="primary" @click="getPurchaseList('nopage')">查询</el-button>
      <el-button type="info" @click="purchaseExport">导出</el-button>
    </div>
    <div class="check-purchase">
      <div>选中商品数量：{{ checkNum }}</div>
      <div>选中采购金额：{{ checkPrice }}</div>
    </div>
    <div class="table">
      <el-table border :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        :data="purchaseList" @selection-change="handleSelectionChange" @sort-change="sortChange" stripe
        style="width: 100%">
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column type="index" label="编号"></el-table-column>
        <el-table-column prop="purchase_no" label="采购单号"></el-table-column>
        <el-table-column prop="order_no" label="订单号"></el-table-column>
        <el-table-column prop="items_num" sortable width="190" label="采购商品数量"></el-table-column>
        <el-table-column prop="items_money" sortable width="170" label="采购总金额"></el-table-column>
        <el-table-column prop="bonus_points" label="优惠积分"></el-table-column>
        <el-table-column prop="real_pay" label="实际支付金额"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column prop="order_date" label="创建时间"></el-table-column>
        <el-table-column prop="opt_time" label="入库时间">
          <template slot-scope="scope">
            {{ scope.row.opt_time || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="详情" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getPurchaseDetail(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background :current-page="page" @size-change="handleSizeChange" @current-change="curretnChange"
        @prev-click="curretnChange" @next-click="curretnChange" :page-sizes="[10, 20, 30, 40]"
        layout="total,sizes,prev, pager, next,jumper" :hide-on-single-page="false" :total="total"></el-pagination>
    </div>
    <el-dialog title="采购单详情" :close-on-press-escape="false" :show-close="true" :close-on-click-modal="false"
      :visible.sync="detailVisible" width="80%">
      <div>
        <div class="detail">
          <div v-if="currentPurchaseDetail.purchase_no">采购单号：{{ currentPurchaseDetail.purchase_no }}</div>
          <div v-if="currentPurchaseDetail.items_num">采购商品数量：{{ currentPurchaseDetail.items_num }}</div>
          <div v-if="currentPurchaseDetail.items_money">采购总金额：￥{{ currentPurchaseDetail.items_money }}</div>
          <div v-if="currentPurchaseDetail.status">状态：{{ currentPurchaseDetail.status }}</div>
        </div>
        <div class="down-order" v-if="currentPurchaseDetail.order_date">创建时间：{{ currentPurchaseDetail.order_date }}</div>
        <div class="select" style="margin-bottom: 10px">
          <div class="input">
            <div>关键词：</div>
            <el-input v-model="detailInput" placeholder="商品条码/商品名称"></el-input>
          </div>
          <el-button type="primary" @click="getPurchaseDetail('nopage')">查询</el-button>
          <el-button type="info" @click="purchaseDetailExport">导出</el-button>
        </div>
        <el-table border height="350" :cell-style="{ textAlign: 'center' }" @sort-change="sortDetailChange"
          :header-cell-style="{ textAlign: 'center' }" :data="detailList" stripe style="width: 100%">
          <el-table-column type="index" label="编号"></el-table-column>
          <!-- <el-table-column prop="item_id" sortable label="商品ID"></el-table-column> -->
          <el-table-column prop="item_name" label="商品名称"></el-table-column>
          <el-table-column prop="item_barcode" label="商品条码"></el-table-column>
          <el-table-column prop="item_single" label="规格"></el-table-column>
          <el-table-column prop="batch_price" label="采购单价"></el-table-column>
          <el-table-column prop="item_num" sortable label="采购数量"></el-table-column>
          <el-table-column prop="total_price" sortable label="采购总价"></el-table-column>
          <el-table-column prop="bonus_points" label="优惠积分"></el-table-column>
          <el-table-column prop="real_pay" label="支付金额"></el-table-column>
          <!-- <el-table-column prop="status" label="状态"></el-table-column> -->
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :hide-on-single-page="false" :current-page="detailPage"
            @current-change="curretnDetailChange" @prev-click="curretnDetailChange" @next-click="curretnDetailChange"
            layout="total,prev, pager, next" :total="detailTotal"></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="closeDetailVisible">关闭</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDateTime, urlEncode, NumberAdd } from '@/utils/util';
import { BASE_URL2 } from '../request/request.config';
export default {
  name: 'purchase',
  data() {
    return {
      value1: [],
      detailInput: '',
      input: '',
      page: 1,
      limit: 10,
      detailLimit: 10,
      checkNum: 0,
      checkPrice: 0,
      order_by: '',
      detail_order_by: '',
      detailPage: 1,
      detailVisible: false,
      statusOptions: [],
      detailList: [],
      detailTotal: 20,
      statusValue: '',
      currentPurchaseDetail: {},
      purchaseList: [],
      multipleSelection: [],
      purchaseStatistics: {},
      total: 20,
    }
  },
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
      }
    }
  },
  watch: {
    detailVisible(newName, oldName) {

      if (newName === false) {
        this.detailInput = ''
        this.detailPage = 1
        this.value1 = []
        this.currentPurchaseDetail = {}
      }
    }
  },
  created() {
    this.getPurchaseList()
    this.getPurchaseStatus()
    this.getPurchaseStatistics()
  },
  methods: {
    getPurchaseStatistics() { // 获取采购统计
      this.$http2
        .get('/merapi/shop/getPurchaseStatistics')
        .then(res => {
          this.purchaseStatistics = res.data.data
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    sortChange(e) { // 外层排序方式
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.order_by = orderBy + '__desc'
      } else {
        this.order_by = orderBy + '__asc'
      }
      this.getPurchaseList('nopage')
    },
    sortDetailChange(e) { // 外层排序方式
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.detail_order_by = orderBy + '__desc'
      } else {
        this.detail_order_by = orderBy + '__asc'
      }
      this.getPurchaseDetail('nopage')
    },
    handleSizeChange(e) {
      this.limit = e
      this.getPurchaseList()
    },
    closeDetailVisible() {
      this.detailVisible = false
      this.detailInput = ''
      this.detailPage = 1
      this.value1 = []
      this.currentPurchaseDetail = {}
    },
    getPurchaseDetail(e) {
      // eslint-disable-next-line eqeqeq
      this.detailPage = e == 'nopage' ? 1 : this.detailPage
      e = this.currentPurchaseDetail.purchase_id ? this.currentPurchaseDetail : e
      this.currentPurchaseDetail = e
      // 获取采购单详情

      // eslint-disable-next-line camelcase
      var purchase_id = e.purchase_id
      var keyword = this.detailInput
      var limit = this.detailLimit
      // eslint-disable-next-line camelcase
      var order_by = this.detail_order_by
      var page = this.detailPage
      this.$http2
        .post('/merapi/purchase/detail/item_lst', { order_by, purchase_id, keyword, page, limit })
        .then(res => {
          this.detailList = res.data.data.list
          this.detailTotal = res.data.data.total
          this.detailVisible = true
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    purchaseDetailExport() { // 采购单详情导出
      var keyword = this.detailInput

      // eslint-disable-next-line camelcase
      var purchase_id = this.currentPurchaseDetail.purchase_id;
      var token = window.localStorage.getItem('token');
      try {
        let params = {
          keyword, purchase_id,
          token,
        }
        let data = urlEncode(params)
        window.location.href = BASE_URL2 + '/merapi/purchase/detail/export?' + data
      } catch {
        this.$message.error('服务器繁忙，请稍后重试')
      }

    },
    prevClick(e) {
      this.page = e
      this.getPurchaseList()
    },
    nextClick(e) {
      this.page = e
      this.getPurchaseList()
    },
    curretnDetailChange(e) {
      this.detailPage = e
      this.getPurchaseDetail()
    },
    curretnChange(e) {
      // 切换page
      this.page = e
      this.getPurchaseList()
    },
    handleSelectionChange(val) {
      // 多选值改变
      this.multipleSelection = val
      let checkNum = 0, checkPrice = 0;
      this.multipleSelection.forEach(element => {

        checkNum += Number(element.items_num)
        checkPrice = NumberAdd(checkPrice, Number(element.items_money))
      });
      this.checkNum = checkNum
      this.checkPrice = checkPrice
    },
    getPurchaseList(e) {
      // 获取采购单列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      var keyword = this.input
      var status = this.statusValue
      // eslint-disable-next-line camelcase
      var order_by = this.order_by
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (Object.prototype.toString.call(this.value1) === '[object Array]') {
        if (this.value1[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.value1[0])
        }
        if (this.value1[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.value1[1])
        }
      }
      this.page = e === 'nopage' ? 1 : this.page
      var page = this.page
      var limit = this.limit
      this.$http2
        .post('/merapi/purchase/list', {
          shop_id,
          keyword,
          order_by,
          status,
          start_date,
          end_date,
          page,
          limit
        })
        .then(res => {
          this.purchaseList = res.data.data.list
          this.total = res.data.data.total
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    getPurchaseStatus() {
      // 获取采购单状态
      // eslint-disable-next-line camelcase
      // var shop_id = this.shopInfo.shop_id
      this.$http2
        .post('/merapi/purchase/status')
        .then(res => {
          this.statusOptions = res.data.data
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    purchaseExport() {

      var keyword = this.input
      var status = this.statusValue
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (this.value1[0]) {
        // eslint-disable-next-line camelcase
        start_date = formatDateTime(this.value1[0])
      }
      if (this.value1[1]) {
        // eslint-disable-next-line camelcase
        end_date = formatDateTime(this.value1[1])
      }
      var multipleSelection = this.multipleSelection
      // eslint-disable-next-line camelcase
      var ids = []
      if (multipleSelection.length > 0) {
        multipleSelection.forEach(ele => {
          ids.push(ele.purchase_id)
        })
      }
      // eslint-disable-next-line camelcase
      ids = ids.join(',')
      var token = window.localStorage.getItem('token');
      try {
        let params = {
          keyword,
          status,
          start_date,
          end_date,
          ids,
          token,
        }
        let data = urlEncode(params)
        window.location.href = BASE_URL2 + '/merapi/purchase/export?' + data
      } catch {
        this.$message.error('服务器繁忙，请稍后重试')
      }

    }
  }
}
</script>

<style scoped lang="less">
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 37px;

  .item {
    background-color: #fff;
    width: 362px;
    height: 180px;
    box-shadow: 0px 4px 12px 0px rgba(84, 95, 167, 0.1);
    font-family: Roboto;
    border: 0px solid rgba(239, 242, 245, 1);
    position: relative;
    padding: 25px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .des {
      color: #75848C;
      font-size: 16px;
      // margin-bottom: 15px;
    }

    .dfcs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }
    }

    span {
      font-size: 26px;
      font-weight: 600;
    }

    .el-icon-warning {
      position: absolute;
      top: 25px;
      font-size: 24px;
      color: #d2d6d9;
      right: 25px;
    }
  }
}

.screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;

  div {
    font-size: 16px;
    color: #222;
  }
  .el-button {
    background-color: #0078fb;
  }
  


  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;

    }

    .el-input {
      width: 200px;
    }
  }
}

.check-purchase {
  display: flex;
  margin-top: 20px;
  align-items: center;
  font-size: 18px;

  div {
    margin-right: 20px;
  }
}

.table {
  margin-top: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  align-items: center;
}

.select {
  display: flex;
  margin-top: 20px;
  align-items: center;

  .logistics {
    margin: 0;
  }

  .el-button {
    margin-left: 30px;
    background-color: #0078fb;
  }

  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 16px;
    }

    .el-input {
      width: 400px;
      font-size: 14px;
    }
  }
}

.down-order {
  margin-top: 20px;
  font-size: 18px;
}

.detail {
  font-size: 18px;
  display: flex;
  align-items: center;

  div {
    margin-right: 20px;
  }
}</style>
